import React, { useState } from 'react';
import { PopupWidget } from 'react-calendly';
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
export const CeoMessage = ({ title, desc, desc2, hidden, image, buttonText }) => {
  const currentPage = useLocation();
  const [showmodal, setshowmodal] = useState('hidden')
  const [emailloading, setemailloading] = useState(' Hear From An Expert')
  const [loader, setloader] = useState('none')
  const myPopupModal = () => {
    showmodal === 'hidden' ? setshowmodal('flex') : setshowmodal('hidden')
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (reqData) => {
    setemailloading('Email Sending')
    setloader('')
    try {
      await axios.post('https://ewdtech.com/ewd/api/letsTalkEmail', reqData);
      setshowmodal('hidden');
      setemailloading('Hear From An Expert')
      setloader('none')
      const Toast = Swal.mixin({
        position: "center",
        icon: "success",
        theme: "dark",
        showConfirmButton: false,
        timer: 1500,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        text: "MASSAGE SEND. WE WILL CONTACT WITH YOU SOON.",
      });
      reset();
    } catch (error) {
      alert('Error sending email!');
    }
  };
  return (
    <React.Fragment>
      <section className="relative mainPaddingTop maindPadding">
        <div className="contactusTopBg left-0" />
        <div className="flex justify-end book-meeting-flex relative">
          <div className=" book-meeting-home-col-1 ">
            <div className="bg-F5F7FA book-meeting-home lg:px-8 px-4 lg:py-12 py-7 mt-20 ">
              <h1 className=" book-meeting-title book-meeting-title-ser bold-font    font-black">
                {title}
              </h1>
              <p className="leading-6 pt-6 ">
                {desc}
                {' '}
              </p>
              <p className={`leading-6 pt-6 ${hidden}`}>
                {desc2}
              </p>
              <div className="pt-12">
                <div className="flex pt-12">

                  {
                    (currentPage.pathname === "/services/seo") || (currentPage.pathname === "/services/ecommerce") || (currentPage.pathname === "/services/shopify") || (currentPage.pathname === "/services/branding") || (currentPage.pathname === "/services/social") || (currentPage.pathname === "/services/webdesigndevelopment") || (currentPage.pathname === "/services/ppcmanagement") || (currentPage.pathname === "/services/leadgeneration") || (currentPage.pathname === "/clients")
                      ? (
                        <button onClick={myPopupModal} className=" bg-16A085 text-white uppercase w-full font-semibold  px-7 py-3  text-center">
                          Get Free Proposal
                        </button>

                      ) : (
                        <button className=" bg-16A085 text-white uppercase w-full font-semibold  px-7  text-center">
                          <PopupWidget
                            url="https://calendly.com/faisaal-279/30min?month=2023-03"
                            rootElement={document.getElementById('root')}
                            text={buttonText}
                            textColor="#ffffff"
                            color="#16A085"
                          />
                        </button>
                      )}

                </div>
              </div>
            </div>
          </div>
          <div className="book-meeting-home-col-2 relative mt-0">
            <img src={image} alt="..." className="w-full" />
          </div>
        </div>
        {/* // MODAL */}
        <div id="staticModal" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true" className={`fixed shadow backdrop-blur-sm top-0 left-0 right-0 z-50 ${showmodal} flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full`}>
          <div className="relative w-full h-full max-w-2xl md:h-auto">

            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold  dark:text-white">
                  Get Free Proposal
                </h3>
                <button type="button" onClick={myPopupModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="staticModal">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
              </div>

              <div className="p-6 space-y-6">
                <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                  {/* Full Name &  Company Name */}
                  <div className="flex mb-6 flex-wrap">
                    <div className="lg:w-6/12 md:w-6/12 sm:w-full w-full lg:pr-5 md:pr-0 sm:pr-0 pr-0">
                      <div className="flex flex-col">
                        <label className="font-semibold mb-3" htmlFor="name">
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          {...register("name", {
                            required: true,
                          })}
                          className=" h-14 inputpopup"
                        />
                        {errors.name && (
                          <span className="text-red-700 pt-2">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Email Address &  Phone Number */}
                    <div className="lg:w-6/12 md:w-6/12 sm:w-full w-full ">
                      <div className="flex flex-col ">
                        <label
                          className="font-semibold mb-3"
                          htmlFor="email"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                          id="email"
                          className=" h-14 inputpopup"
                        />
                        {errors.email?.type === "required" && (
                          <span className="text-red-700 pt-2">
                            This field is required
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="text-red-700 pt-2">
                            Invalid email address
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Full Name &  Company Name */}

                  {/* Message */}
                  {/* Monthly Budget */}
                  <div className="flex flex-col mb-6">
                    <label className="font-semibold mb-3" htmlFor="budget">
                      Estimated budget
                    </label>
                    <select
                      name="budget"
                      id="budget"
                      {...register("budget", {
                        required: false,
                      })}
                      defaultValue={""}
                      className="select  h-14 inputpopup"
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="500$-1000$">500$ - 1000$</option>
                      <option value="1000$-1500$">1000$ - 1500$</option>
                      <option value="1500$-2000$">1500$ - 2000$</option>
                      <option value="2000$-2500$">2000$ - 2500$</option>
                      <option value="2500$-3000$">2500$ - 3000$</option>
                    </select>
                  </div>
                  {/* Monthly Budget */}
                  <div className="flex flex-col mb-6">
                    <label className="font-semibold mb-3" htmlFor="message">
                      Project Description

                    </label>
                    <textarea
                      name="message"
                      id="message"
                      {...register("message", {
                        required: false,
                      })}
                      cols={20}
                      rows={3}
                      className="inputpopup"
                      defaultValue={""}
                    />
                  </div>
                  <button
                    style={{ height: '70px' }}
                    type="submit"
                    className="px-5 btnHeight py-4 flex items-center justify-center uppercase w-full font-semibold bg-16A085 text-white"
                  >
                    {emailloading} <svg
                      style={{ display: `${loader}` }}
                      className={`${loader}`}
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="40px"
                      height="40px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g transform="translate(80,50)">
                        <g transform="rotate(0)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity={1}>
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.875s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.875s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(71.21320343559643,71.21320343559643)">
                        <g transform="rotate(45)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.875">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.75s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.75s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(50,80)">
                        <g transform="rotate(90)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.75">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.625s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.625s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(28.786796564403577,71.21320343559643)">
                        <g transform="rotate(135)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.625">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.5s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.5s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(20,50.00000000000001)">
                        <g transform="rotate(180)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.5">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.375s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.375s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(28.78679656440357,28.786796564403577)">
                        <g transform="rotate(225)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.375">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.25s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.25s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(49.99999999999999,20)">
                        <g transform="rotate(270)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.25">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.125s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="-0.125s"
                            />
                          </circle>
                        </g>
                      </g>
                      <g transform="translate(71.21320343559643,28.78679656440357)">
                        <g transform="rotate(315)">
                          <circle cx={0} cy={0} r={6} fill="#ffffff" fillOpacity="0.125">
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="0s"
                              values="1.5 1.5;1 1"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              keyTimes="0;1"
                              dur="1s"
                              repeatCount="indefinite"
                              values="1;0"
                              begin="0s"
                            />
                          </circle>
                        </g>
                      </g>
                    </svg>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* // MODAL */}
      </section>

    </React.Fragment>
  );
};
